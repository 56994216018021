import { DataService } from "./dataService";
import { PogoMap } from "./map";
import { Icon } from "./icon";
import { ResearchMarker, TeamRocketMarker, GymMarker, Marker, PokestopMarker } from "./marker";
import { UpdateMode } from "./model";
import { GoogleAnalitics } from "./ga";
declare var $: any;
declare var L: any;
export abstract class Dialog {
    constructor(public instanceName: string, public selector: string, protected map: PogoMap) {

    }
    public hide() {
        $(this.selector).modal('hide');
    }
}
function getNodeId(pokemoId: number): string {
    return `boss_${pokemoId}`;
}
function getNodeLevel(level: number): string {
    return `egg_${level}`;
}
export class SearchDialog extends Dialog {
    async init() {
        let $search = $('#searchText');
        $search.on('input', (event) => {
            let text: string = $("#searchText").val();
            if (text && text.length > 2) {
                this.search();

            }
            else {
                $("#searchResultPane").html(``)
            }
            event.stopPropagation();
        });
        $search.keypress((event) => {
            var keycode = (event.keyCode ? event.keyCode : event.which);
            let text: string = $search.val();
            if (keycode == '13') {
                this.search();

            }
            event.stopPropagation();
        });
        $(this.selector).on('hidden.bs.modal', (event) => {
            this.map.map.invalidateSize();
        });

        $(this.selector).on('show.bs.modal', (event) => {
            setTimeout(() => {
                //   console.log('focus', $('#searchText'));

                $search.focus();
                try {
                    $search.selectionStart = 0;
                    $search.selectionEnd = $search.value.length + 1;
                }
                catch (e) {
                    $search.select();
                }

            }, 10)

        });
    }
    public clear() {
        $("#searchText").val(null)
        $("#searchResultPane").html(``)
    }
    public search() {
        let type: string = $("input[name='searchOptions']:checked").val()
        let text: string = $("#searchText").val()
        GoogleAnalitics.registerUrl(`/search/${type}`);
        let innerHtml = "";
        if (!text || text.length == 0) {
            $("#searchResultPane").html(``);
            return;
        }
        this.map.dataService.search(type, text).then((items) => {
            items.forEach((item) => {
                // console.log('search',items);
                // txt += `<button class="btn btn-light btn-sm" href="#" onclick="selectBoss('${boss.name.replace("\'", "\\\'").replace("\"", "\\\"")}',${boss.pokemonId},${boss.level})" >${boss.name}</button>`;
                if (type.toLowerCase() == 'task') {
                    let imageUrl = `assets/img/quest.png`;
                    var icon = Icon.getIcon(item.rewardId, 'research');
                    if (icon) {
                        imageUrl = icon.options.iconUrl;
                    }
                    let pokestopSafe = item.pokestop.replace(/"/gi, "&quot;").replace(/'/gi, "\\\'");

                    innerHtml += `
                    <li onclick="PogoMap.searchDialog.jumpTo('${type}','${pokestopSafe}',${item.latitude},${item.longitude});"><img class="img-icon" src="${imageUrl}">&nbsp;${item.pokestop}</li>
                `
                }
                else {
                    let itemSafe = item.name.replace(/"/gi, "&quot;").replace(/'/gi, "\\\'");
                    innerHtml += `
                    <li onclick="PogoMap.searchDialog.jumpTo('${type}','${itemSafe}',${item.latitude},${item.longitude});"><img class="img-icon" src="assets/img/${type}.png">&nbsp;${item.name}</li>
                `
                }

            });

            if (items && items.length) {
                // console.log("refresh")
                $("#searchResultPane").html(`<ul class="result-list">${innerHtml}</ul>`)
            }
            else {
                $("#searchResultPane").html(`<ul class="result-list">No items found</ul>`)
            }

        }, (err) => {
            console.error(err)
        })
    }
    public jumpTo(type: string, name: string, latitude: number, longitude: number) {
        this.hide();
        this.map.jumpTo(type, name, latitude, longitude);
        let marker: Marker;


    }
}
export class RaidDialog extends Dialog {
    public selectedRaid = {
        name: null,
        level: 0,
        pokemonId: 0,
        pokemonName: null,
        variance:"00",
        minutes: 0,
        last_modified: null,
        comments: null

    };

    async init() {
        let bosses = await this.map.dataService.getAvailableRaidBoss();
        //  console.log("bosses", bosses)
        let txt = "Er is geen boss beschikbaar";
        if (bosses && bosses.length) {
            txt = "";
            let bossMatrix = new Array();
            for (let a = 0; a < 6; a++) {
                bossMatrix[a] = new Array();
            }
            bosses.forEach((boss) => {
                let levelArray = bossMatrix[boss.level-1];
                levelArray.push(boss)
            });
            let rowLength = 0;
            for (let a = 0; a < 6; a++) {
                if (bossMatrix[a].length > rowLength) {
                    rowLength = bossMatrix[a].length;
                }

            }
            txt = `<table class="pokemon-table">`;
            for (let r = 0; r <= rowLength; r++) {
                txt += `<tr>`;
                for (let c = 0; c < 6; c++) {
                    let levelArray = bossMatrix[c]
                    if (levelArray.length > 1) {
                        txt += `<td>`;
                        if(r<levelArray.length){
                            let boss = levelArray[r];
                       
                            let id: string
                            if (boss.pokemon_id) {
                                id = getNodeId(boss.pokemon_id);
                            }
                            else {
                                id = getNodeLevel(boss.level);
                            }
                            txt += `
                            <span class="image-button" style="">
                                <span><img class="btn btn-light btn-sm btn-image fill" id="${id}" data-level="${boss.level}" src="${boss.imgUrl}" onclick="${this.instanceName}.selectBoss(this,'${boss.name.replace("\'", "\\\'").replace("\"", "\\\"")}',${boss.pokemon_id},${boss.level},${boss.iconId})" alt="${boss.name.replace("\'", "\\\'").replace("\"", "\\\"")}"/></span>
                            `    
                            if(boss.level!=6){
                                txt+=`<span class="level-badge">${boss.level}</span>`;
                            }
                            txt+=`</span>`;

                        }
                        txt += `</td>`;
                    }
                   
                    
                }
                txt += `</tr>`;
            }
            txt += `</table>`;
            console.log(bossMatrix);
            // bosses.forEach((boss => {
            //     if(boss.type=='egg'){
            //         bossMatrix[boss.Level].push(boss);
            //     }
            // });
            // bosses.forEach((boss) => {
            //     if(boss.type=='pokemon'){
            //         bossMatrix[boss.Level].push(boss);
            //     }
            // });
            // bosses.forEach((boss) => {
            //     // txt += `<button class="btn btn-light btn-sm" href="#" onclick="selectBoss('${boss.name.replace("\'", "\\\'").replace("\"", "\\\"")}',${boss.pokemonId},${boss.level})" >${boss.name}</button>`;
            //     let id: string
            //     if (boss.pokemon_id) {
            //         id = getNodeId(boss.pokemon_id);
            //     }
            //     else {
            //         id = getNodeLevel(boss.level);
            //     }

            //     txt += `
            //     <span class="image-button" style="">
            //         <span><img class="btn btn-light btn-sm btn-image fill" id="${id}" data-level="${boss.level}" src="${boss.imgUrl}" onclick="${this.instanceName}.selectBoss(this,'${boss.name.replace("\'", "\\\'").replace("\"", "\\\"")}',${boss.pokemon_id},${boss.level})" alt="${boss.name.replace("\'", "\\\'").replace("\"", "\\\"")}"/></span>
            //         <span class="level-badge">${boss.level}</span>
            //     </span>
            //     `
            // });
        }
        $(this.selector).on('hidden.bs.modal', (event) => {
            this.resetAddRaid();
            this.map.map.invalidateSize();
        });

        $(this.selector).on('show.bs.modal', (event) => {

            let gym = $(event.relatedTarget).data('gym')
            let boss = $(event.relatedTarget).data('boss')
            let end = $(event.relatedTarget).data('end')
            let level = $(event.relatedTarget).data('level')
            let pokemonId = $(event.relatedTarget).data('pokemonid')

            let updateMode = parseInt($(event.relatedTarget).data('updatemode'))

            this.selectedRaid.comments = null;
            this.selectedRaid.pokemonName = null;
            this.selectedRaid.pokemonId = 0;
            this.selectedRaid.name = gym;

            GoogleAnalitics.registerUrl(`/raid/${UpdateMode[updateMode]}`)

            $("#bossListLabel").text(updateMode == UpdateMode.add ? "Add raid" : "Update raid");
            $("#addRaidButton").text(updateMode == UpdateMode.add ? "Add" : "Update");
            if (updateMode == UpdateMode.update) {

                this.selectedRaid.pokemonName = boss;
                this.selectedRaid.pokemonId = pokemonId;
                this.selectedRaid.level = level;
                let endDate = new Date(end);
                let startDate = new Date(end);
                let now = new Date();
                startDate.setMinutes(endDate.getMinutes() - DataService.bossTimeMinutes);

                // console.log(`gym:${gym} start:${startDate} end:${endDate} boss:${boss} level:${level} mode:${updateMode} pokemonId:${pokemonId}`,this.selectedRaid);

                $("#selectBossLabel").text('Raid Boss')
                $("#collapseBoss").hide();
                $("#collapseBoss").collapse('hide');
                $("#selectBoss").show();


                let starHtml = "";
                for (let i = 1; i <= 5; i++) {
                    starHtml += `<span class="fa fa-star ${i <= level ? 'boss-checked' : ''}"></span>`;
                }
                $("#selectedBossRating").html(starHtml)
                let diffTime = endDate.getTime() - now.getTime();
                let $pokemonButton: any;
                if (pokemonId && diffTime <= 45 * 60 * 1000) {
                    $pokemonButton = $(`#${getNodeId(pokemonId)}`);
                }
                else {
                    $pokemonButton = $(`#${getNodeLevel(level)}`);
                }





                if (pokemonId != 'undefined' && diffTime <= 45 * 60 * 1000) {
                    $("#raidTimeEgg").hide();
                    $("#raidTimeBoss").show();
                    $("#raidTimeLabel").text('Minutes remaining')
                    $pokemonButton = $(`#${getNodeId(pokemonId)}`);
                    diffTime = endDate.getTime() - now.getTime();
                    if (diffTime > 0) {
                        $("#raidTimeBoss").val(Math.ceil(diffTime / (1000 * 60)))
                        //console.log("Minutes",Math.ceil(diffTime/(1000*60)))
                    }

                }
                else {
                   // boss = `Level ${level}`;

                    $("#raidTimeEgg").show();
                    $("#raidTimeBoss").hide();
                    $("#raidTimeLabel").text('Minutes until hatch')
                    $pokemonButton = $(`#${getNodeLevel(level)}`);
                    diffTime = startDate.getTime() - now.getTime();
                    if (diffTime > 0) {
                        $("#raidTimeEgg").val(Math.ceil(diffTime / (1000 * 60)))
                        //console.log("Minutes",Math.ceil(diffTime/(1000*60)))
                    }
                    diffTime = endDate.getTime() - now.getTime();
                    if (diffTime > 0) {
                        $("#raidTimeBoss").val(Math.ceil(diffTime / (1000 * 60)))
                        //console.log("Minutes",Math.ceil(diffTime/(1000*60)))
                    }
                }
                $("#selectedBoss").text(boss)
                let url = $pokemonButton.attr("src")
                if (url) $("#selectedBossImage").attr("src", url);
                $("#timePane").show();
                $("#addRaidButton").show();
            }
            else {
                $("#selectBossLabel").text('Select a raid boss')
                $("#selectBoss").hide();
                $("#collapseBoss").collapse('show');

            }

            this.map.map.closePopup();
        })
        let modal = $("#modalBossListOptions")
        modal.html(txt);
        let $timeEgg = $('#raidTimeEgg');
        for (let i = DataService.eggTimeMinutes; i > 0; i--) {
            $timeEgg.append(`<option value="${i}">${i}</option>`);
        }
        let $timeBoss = $('#raidTimeBoss');
        for (let i = DataService.bossTimeMinutes; i > 0; i--) {
            $timeBoss.append(`<option value="${i}">${i}</option>`);
        }

    }
    public startSelectBoss() {
        $("#selectBoss").hide();
        $("#collapseBoss").show();
        $("#selectBossLabel").text('Select a raid boss')
        $("#timePane").hide();
        $("#addRaidButton").hide();
        $("#modalBossListOptions .btn").removeClass("active");
        $("#collapseBoss").collapse('show');
    }
    public selectBoss(el: any, name: string, id: number, level: number,iconId:string) {
        this.selectedRaid.pokemonName = name;
        this.selectedRaid.pokemonId = id;
        this.selectedRaid.level = level;
        this.selectedRaid.variance = iconId;
        let $el = $(el);
        //set active buttons
        $("#modalBossListOptions .btn").removeClass("active");
        $el.addClass("active")
        let url = $el.attr("src")
        if (url) $("#selectedBossImage").attr("src", url);
        $("#selectBossLabel").text('Raid boss')

        if (id) {
            $("#raidTimeEgg").hide();
            $("#raidTimeBoss").show();

            $("#raidTimeLabel").text('Minutes remaining')
        }
        else {
            $("#raidTimeEgg").show();

            $("#raidTimeBoss").hide();
            $("#raidTimeLabel").text('Minutes until hatch')
        }
        let starHtml = "";
        for (let i = 1; i <= 5; i++) {
            starHtml += `<span class="fa fa-star ${i <= level ? 'boss-checked' : ''}"></span>`;
        }
        $("#selectedBossRating").html(starHtml)
        $("#selectedBoss").text(name)
        //collapse selection
        $("#collapseBoss").hide();
        $("#collapseBoss").collapse('hide');
        $("#selectBoss").addClass("active").show();
        $("#timePane").show();
        $("#addRaidButton").show();

        //console.log(this.selectedRaid);
    }
    public cancelAddRaid() {
        $('#bossList').modal('hide');

    }
    public resetAddRaid() {
        $("#selectedBoss").text('Select a raid boss')
        $("#modalBossListOptions .btn").removeClass("active");
        $("#selectBossLabel").text('Select a raid boss')
        $("#selectBoss").hide();
        $("#collapseBoss").show();
        $("#collapseBoss").collapse('show');
        $("#timePane").hide();
        $("#addRaidButton").hide();
        $("#raidTimeBoss").val(DataService.bossTimeMinutes);
        $("#raidTimeEgg").val(DataService.eggTimeMinutes);
    }
    public open(gymName: string, updateMode: UpdateMode = UpdateMode.add) {

        $(this.selector).modal({
            keyboard: false
        })

    }
    public addRaid() {
        //  console.log("addRaid",this.selectedRaid);
        if (this.selectedRaid.pokemonId) {
            this.selectedRaid.minutes = parseInt($('#raidTimeBoss').children("option:selected").val());
            let lastMatch: string
            let re = /\(([^)(]*)\)/ig;
            let result: RegExpExecArray;
            while ((result = re.exec(this.selectedRaid.pokemonName)) !== null) {
                if (result.length > 0) {
                    // console.log('match comments : ' + result[1]);
                    lastMatch = result[1].trim();

                }

            }
            if (lastMatch) {
                this.selectedRaid.comments = lastMatch;
            }
        }
        else {
            this.selectedRaid.minutes = parseInt($('#raidTimeEgg').children("option:selected").val()) + DataService.bossTimeMinutes;
        }
        this.selectedRaid.last_modified = new Date();
        this.map.dataService.addRaid(this.selectedRaid).then(() => {
            this.map.raidDialog.hide()

            this.map.refresh().then(() => {
                // this.resetAddRaid();
            })
        })

    }
}
export class ResearchDialog extends Dialog {
    public selectedStop = {
        name: null,
        latitude: 0,
        longitude: 0
    };
    async init() {
        let researches = await this.map.dataService.getAvailableReseach();
        //    console.log("researches", researches)
        let txt = "Er is geen research beschikbaar";
        if (researches && researches.length) {
            txt = "";
            researches.forEach((research) => {
                txt += `<button class="btn btn-light btn-sm btn-block" href="#" onclick="${this.instanceName}.addFieldResearch('${research.name.replace("\'", "\\\'").replace("\"", "\\\"")}',${research.rewardId})" >${research.name}</button>`;
            });
        }
        let modal = $("#modalResearchOptions")
        modal.html(txt);
        $(this.selector).on('hidden.bs.modal', (event) => {
            this.map.map.invalidateSize();
        });

        $(this.selector).on('show.bs.modal', (event) => {
            let pokestop = $(event.relatedTarget).data('pokestop')
            let latitude = $(event.relatedTarget).data('latitude')
            let longitude = $(event.relatedTarget).data('longitude')
            let updateMode = parseInt($(event.relatedTarget).data('updatemode'))
            this.selectedStop.name = pokestop;
            this.selectedStop.latitude = latitude;
            this.selectedStop.longitude = longitude;
            this.map.map.closePopup();
            GoogleAnalitics.registerUrl(`/research/${UpdateMode[updateMode]}`)
            $("#fieldResearchLabel").text(updateMode == UpdateMode.add ? "Add field research" : "Update field research")
        })
    }
    public addFieldResearch(researchName, rewardId) {
        this.map.dataService.AddResearch(researchName, this.selectedStop.name).then((result) => {
            $(this.map.researchDialog.selector).modal('hide');
            let researchMarker: ResearchMarker = new ResearchMarker();
            let research = { research: researchName, rewardId: rewardId, pokestop: this.selectedStop.name, latitude: this.selectedStop.latitude, longitude: this.selectedStop.longitude }
            researchMarker.addMarker(research, this.map.map, this.instanceName, true);
        })


    }
}
export class TeamRocketDialog extends Dialog {
    public selectedStop = {
        name: undefined,
        rewardId: undefined,
        latitude: 0,
        longitude: 0
    };
    async init() {
        let teamRocket = await this.map.dataService.getAvailableShadowPokemon();
        //  console.log("bosses", bosses)
        let txt = "Er is zijn geen shadow pokemon beschikbaar";
        if (teamRocket.pokemon && teamRocket.pokemon.length) {
            txt = "";
            teamRocket.pokemon.forEach((pokemon) => {
                // txt += `<button class="btn btn-light btn-sm" href="#" onclick="selectBoss('${boss.name.replace("\'", "\\\'").replace("\"", "\\\"")}',${boss.pokemonId},${boss.level})" >${boss.name}</button>`;
                let pokemonSafe = pokemon.alias.replace("\'", "\\\'").replace("\"", "\\\"");
                txt += `
                  <span class="image-button" style="">
                      <span><img class="btn btn-light btn-sm fill" src="${pokemon.imgUrl}" onclick="${this.instanceName}.selectPokemon(this,'${pokemonSafe}',${pokemon.dex})" alt="${pokemonSafe}"/></span>
                  </span>
                  `
            });
        }
        let modal = $("#modalShadowListOptions")
        modal.html(txt);
        $(this.selector).on('hidden.bs.modal', (event) => {
            this.map.map.invalidateSize();
        });

        $(this.selector).on('show.bs.modal', (event) => {
            let pokestop = $(event.relatedTarget).data('pokestop')
            let latitude = $(event.relatedTarget).data('latitude')
            let longitude = $(event.relatedTarget).data('longitude')

            $("#teamRocketQuestionLabel").text(`Has team Rocket taken over pokestop "${pokestop}"?`);
            this.selectedStop.name = pokestop;
            this.selectedStop.latitude = latitude;
            this.selectedStop.longitude = longitude;
            this.map.map.closePopup();
            GoogleAnalitics.registerUrl(`/teamrocket`)

            this.selectedStop.rewardId = -8;
            this.resetDialog();
        })

    }
    public startSelectTR() {
        $("#selectTR").hide();

        $("#selectTRLabel").text('Select a shadow pokemon')
        $("#addTrButton").hide();
        $("#modalBossListOptions .btn").removeClass("active");
        $("#collapseTR").show();
        $("#collapseTr").collapse('show');

    }
    public selectPokemon(el: any, name: string, id: number) {
        this.selectedStop.rewardId = id;

        let $el = $(el);
        //set active buttons
        $("#modalBossListOptions .btn").removeClass("active");
        $el.addClass("active")
        let url = $el.attr("src")
        if (url) $("#selectedTRImage").attr("src", url);
        $("#selectBossLabel").text('Raid boss')
        $el.addClass("active")
        $("#selectTRLabel").text(`Shadow pokemon`)
        $("#selectTR").show();
        $("#addTrButton").show();
        $("#selectedTR").text(name)
        //collapse selection
        $("#collapseTR").hide();
        $("#collapseTR").collapse('hide');



        //console.log(this.selectedRaid);
    }
    public resetDialog() {
        $("#selectTRLabel").text('Select a shadow pokemon :')
        $("#modalShadowListOptions .btn").removeClass("active");
        $("#addTrButton").hide();
        $("#selectedBoss").text('Select a raid boss')
        $("#modalBossListOptions .btn").removeClass("active");
        $("#selectTR").hide();

        $("#collapseTR").collapse('show');
        $("#collapseTR").show();
    }
    public addFieldResearch(researchName, rewardId) {
        this.map.dataService.AddResearch(researchName, this.selectedStop.name).then((result) => {
            $(this.map.researchDialog.selector).modal('hide');
            let researchMarker: ResearchMarker = new ResearchMarker();
            let research = { research: researchName, rewardId: rewardId, pokestop: this.selectedStop.name, latitude: this.selectedStop.latitude, longitude: this.selectedStop.longitude }
            researchMarker.addMarker(research, this.map.map, this.instanceName, true);
        })


    }
    public addTask() {
        this.map.dataService.AddTRTask(this.selectedStop.name, this.selectedStop.rewardId).then((result) => {

            $(this.map.teamRocketDialog.selector).modal('hide');
            let trMarker: TeamRocketMarker = new TeamRocketMarker();
            let task = { pokestop: this.selectedStop.name, rewardId: this.selectedStop.rewardId, latitude: this.selectedStop.latitude, longitude: this.selectedStop.longitude, added: new Date() }
            // console.log("add tr marker", task)
            trMarker.addMarker(task, this.map.map, this.instanceName, true);
        });
    }
}