import { reject } from "q";

declare var $: any;

export class DataService{
   
    static eggTimeMinutes = 60;
    static bossTimeMinutes = 45;
    constructor(private endPoint:string){

    }
    static formatTime(time:Date) :string{
        let parts= time.toTimeString().split(':');
        if(parts && parts.length==3){
            parts.pop();
            return parts.join(':');
        }
        else {
            return time.toLocaleTimeString()
        }
    }
    public search(type:string,text:string):Promise<any> {
        return new Promise((resolve) => {
         //   console.log("loadAvailableReseach")
            $.getJSON(this.endPoint + `/api/search/${type}/${encodeURIComponent(text)}`, function (data) {
                resolve(data);
            });
        });
    }
    public getAvailableReseach():Promise<any> {
        return new Promise((resolve) => {
         //   console.log("loadAvailableReseach")
            $.getJSON(this.endPoint + '/api/research/available', function (data) {
                resolve(data);
            });
        });
    }
 
    public getAvailableRaidBoss():Promise<any> {
        return new Promise((resolve) => {
           // console.log("loadAvailableRaidBoss")
            $.getJSON(this.endPoint + '/api/boss/available', function (data) {
    
                resolve(data);
            });
        });
    }
    public getAvailableShadowPokemon():Promise<any> {
        return new Promise((resolve) => {
           // console.log("loadAvailableRaidBoss")
            $.getJSON(this.endPoint + '/api/teamrocket/available', function (data) {
    
                resolve(data);
            });
        });
    }
    public getGyms(neLong: number, neLat: number, swLong: number, swLat: number,oneLong: number, oneLat: number, oswLong: number, oswLat: number):Promise<any> {
        return new Promise((resolve) => {
            $.getJSON(this.endPoint + `/api/gym/swlong/${swLong}/swlat/${swLat}/nelong/${neLong}/nelat/${neLat}/oswlong/${oswLong}/oswlat/${oswLat}/onelong/${oneLong}/onelat/${oneLat}`, function (data) {              
                resolve(data);
            });
        }
        );    
    }
    public getPokestops(neLong: number, neLat: number, swLong: number, swLat: number,oneLong: number, oneLat: number, oswLong: number, oswLat: number):Promise<any> {
        return new Promise((resolve) => {
            $.getJSON(this.endPoint + `/api/pokestop/swlong/${swLong}/swlat/${swLat}/nelong/${neLong}/nelat/${neLat}/oswlong/${oswLong}/oswlat/${oswLat}/onelong/${oneLong}/onelat/${oneLat}`, function (data) {              
                resolve(data);
            });
        }
        );
    
    }
    public getObjects(neLong: number, neLat: number, swLong: number, swLat: number,oneLong: number, oneLat: number, oswLong: number, oswLat: number):Promise<any> {
        return new Promise((resolve) => {
            $.getJSON(this.endPoint + `/api/map/object/swlong/${swLong}/swlat/${swLat}/nelong/${neLong}/nelat/${neLat}/oswlong/${oswLong}/oswlat/${oswLat}/onelong/${oneLong}/onelat/${oneLat}`, function (data) {              
                resolve(data);
            });
        }
        );
    
    }
    public getObjectsByType(neLong: number, neLat: number, swLong: number, swLat: number,oneLong: number, oneLat: number, oswLong: number, oswLat: number,includeGyms:boolean=true,includePokestops:boolean=true):Promise<any> {
        return new Promise((resolve) => {
            $.getJSON(this.endPoint + `/api/map/object/swlong/${swLong}/swlat/${swLat}/nelong/${neLong}/nelat/${neLat}/oswlong/${oswLong}/oswlat/${oswLat}/onelong/${oneLong}/onelat/${oneLat}/gyms/${includeGyms}/pokestops/${includePokestops}`, function (data) {              
                resolve(data);
            });
        }
        );
    
    }
    public AddResearch(researchName, pokestop) {
        let research = {
            pokestop: pokestop,
            name: researchName
        }
        return new Promise((resolve) => {
    
            $.ajax({
                type: "POST",
                url: `${this.endPoint}/api/research`,
                // The key needs to match your method's input parameter (case-sensitive).
                data: JSON.stringify(research),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (data) { resolve(data); },
                failure: function (errMsg) {
                    console.error(errMsg);
                }
            });
        });
    }
    
    public  getReseachTasks(neLong: number, neLat: number, swLong: number, swLat: number):Promise<any> {
        return new Promise((resolve) => {
            $.getJSON(this.endPoint + `/api/research/swlong/${swLong}/swlat/${swLat}/nelong/${neLong}/nelat/${neLat}`, function (data) {
                resolve(data);
            });
        });
    }
    public  getTrTasks(neLong: number, neLat: number, swLong: number, swLat: number):Promise<any> {
        return new Promise((resolve) => {
            $.getJSON(this.endPoint + `/api/teamrocket/swlong/${swLong}/swlat/${swLat}/nelong/${neLong}/nelat/${neLat}`, function (data) {
                resolve(data);
            });
        });
    }
    public getRaids(neLong: number, neLat: number, swLong: number, swLat: number):Promise<any> {
        return new Promise((resolve) => {
            $.getJSON(this.endPoint + `/api/raid/swlong/${swLong}/swlat/${swLat}/nelong/${neLong}/nelat/${neLat}`, function (data) {                              
                resolve(data);
            });
        });
    }
    public addRaid(selectedRaid) {
   
        return new Promise((resolve) => {
    
            $.ajax({
                type: "POST",
                url: `${this.endPoint}/api/raid`,
                // The key needs to match your method's input parameter (case-sensitive).
                data: JSON.stringify(selectedRaid),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (data) { resolve(data); },
                failure: function (errMsg) {
                    console.error(errMsg);
                }
            });
        });
    }
    public AddTRTask(pokestop:string, rewardId:number) {
        let task = {
            pokestop: pokestop ,
            rewardId:undefined         
        }
        if(!rewardId){
            delete task.rewardId;
        }
        else{
            task.rewardId=rewardId;
        }
        return new Promise((resolve) => {
    
            $.ajax({
                type: "POST",
                url: `${this.endPoint}/api/teamrocket`,
                // The key needs to match your method's input parameter (case-sensitive).
                data: JSON.stringify(task),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (data) { resolve(data); },
                failure: function (errMsg) {
                    console.error(errMsg);
                }
            });
        });
    }
}