declare var L: any;

export const IconOptions = L.Icon.extend({
    options: {
        iconSize: [48, 48],
        iconAnchor: [24, 24],
        popupAnchor: [0, 0]
    }
});
export const RaidIcon = L.Icon.extend({
    options: {
        iconSize: [48, 48],
        iconAnchor: [24, 24],
        popupAnchor: [0, 0]
    },
    createIcon: function () {
        var div = document.createElement('div');
        var img = this._createImg(this.options['iconUrl']);
        img.setAttribute("class", "image");
        var imgDiv = document.createElement('div');
        imgDiv.appendChild(img);
        div.appendChild(imgDiv);
        if (this.options['number']) {
            var numdiv = document.createElement('div');
            let className="number default";
            if (this.options['numberClass']){
                className=className+' '+this.options['numberClass'];
            }
            numdiv.setAttribute("class", className);
            numdiv.innerHTML = this.options['number'];
            div.appendChild(numdiv);
        }
        this._setIconStyles(div, 'icon');
        return div;
    },

    //you could change this to add a shadow like in the normal marker if you really wanted
    createShadow: function () {
        return null;
    }
});
export class Icon {
    public static getRaidIcon(id: number, people: number,numberClassName:string, iconclassName?: string, variance: string = "00") {
        let options: any = Icon.getIconConfig(id, iconclassName, variance);
        options.number = people;
        options.numberClass= numberClassName;
        return new RaidIcon(options);
    }
    public static getIcon(id: number, className?: string, variance: string = "00") {
        return new IconOptions(Icon.getIconConfig(id, className, variance));
    }
    public static getIconConfig(id: number, className?: string, variance: string = "00") {
        if (id > 0) {
            var idStr = "" + id;
            while (idStr.length < 3) {
                idStr = "0" + idStr;
            }

            // var baseUrl = `https://raw.githubusercontent.com/whitewillem/PogoAssets/resized/no_border/pokemon_icon_${idStr}_${variance}.png`;
            var baseUrl = `https://github.com/PokeMiners/pogo_assets/raw/master/Images/Pokemon/pokemon_icon_${idStr}_${variance}.png`;
            return { iconUrl: baseUrl, className: className + " shrink5", iconSize: [50, 50], iconAnchor: [25, 25] };

        }
        else {
            var baseUrl = "https://github.com/PokeMiners/pogo_assets/raw/master/Images/Raids/{fileName}";
            var fileName;
            switch (-id) {
                case 9: return { iconUrl: "https://pokemongohub.net/wp-content/uploads/2018/08/Item_0707.png", className: className + " shrink",  iconSize: [50, 50], iconAnchor: [25, 25] };
                case 8: return { iconUrl: "assets/img/team-rocket2.png", className: className + " shrink",  iconSize: [50, 50], iconAnchor: [25, 25] };
                case 7: return { iconUrl: "https://pokemongohub.net/wp-content/uploads/2018/03/Item_1301.png", className: className + " shrink",  iconSize: [50, 50], iconAnchor: [25, 25] };
                case 6: fileName = "raid_egg_3_icon.png";break;
                case 5: fileName = "ic_raid_egg_legendary.png"; break;
                case 5: fileName = "ic_raid_egg_legendary.png"; break;
                case 4: fileName = "ic_raid_egg_rare.png"; break;
                case 3: fileName = "ic_raid_egg_rare.png"; break;
                case 2: fileName = "ic_raid_egg_normal.png"; break;
                case 1: fileName = "ic_raid_egg_normal.png"; break;

            }
            if (fileName)
                return { iconUrl: baseUrl.replace("{fileName}", fileName), className: className + " shrink",  iconSize: [50, 50], iconAnchor: [25, 25] };
        }
        //return "https://raw.githubusercontent.com/ZeChrales/PogoAssets/master/pokemon_icons/pokemon_icon_"+reward.id+"_00.png"
    }
}