import { PogoMap } from "./map/map";
import { DataService } from "./map/dataService";
import { RaidDialog, ResearchDialog } from "./map/dialog";
import { GoogleAnalitics } from "./map/ga";

declare var L: any;
declare var $: any;
declare var window: any;
//const endpoint = "http://localhost:3000";
const endpoint = "https://pogoutrecht.nl:3000";

let ga = new GoogleAnalitics('UA-141698158-1')
let dataService = new DataService(endpoint);
let pogoMap = new PogoMap("PogoMap",dataService);
GoogleAnalitics.init();
pogoMap.initMap();
window.PogoMap=pogoMap;

$(document).ready(function () {
    pogoMap.loadMapData().then(() => {
        console.log("map ready");
    })
});
