import { CookieManager } from "./cookie";
import { DataService } from "./dataService";
import { RaidDialog, ResearchDialog, TeamRocketDialog, SearchDialog } from "./dialog";
import { GymMarker, PokestopMarker, ResearchMarker, RaidMarker, TeamRocketMarker, Marker } from "./marker";
import { Icon } from "./icon";
import { GoogleAnalitics } from "./ga";
import { UpdateMode } from "./model";
import { ClipBoard } from "./clipboard";

declare var L: any;
declare var $: any;
let iconOptions = L.Icon.extend({
    options: {
        iconSize: [36, 36],
        iconAnchor: [16, 16],
        popupAnchor: [0, 0]
    }
});
interface IMapBounds {
    NE: {
        lat: number,
        lng: number
    },
    SW: {
        lat: number,
        lng: number
    }
}
export class PogoMap {
    private lat: number = 52.090676;
    private lon: number = 5.1213190;
    private zoom: number = 18;
    public map: any;
    private isInitialised: Boolean = true;
    private lastZoom = 0;
    private showingPokestop = false;
    private showingGyms = false;
    private iconOptions: any;
    private markers = { pokestops: {}, research: {}, raids: {} };
    public raidDialog: RaidDialog;
    public researchDialog: ResearchDialog;
    public teamRocketDialog: TeamRocketDialog;
    public searchDialog: SearchDialog;
    public layers = { pokestop: new L.featureGroup(), gym: new L.featureGroup(), raid: new L.featureGroup(), research: new L.featureGroup(), teamRocketGrunt: new L.featureGroup() };
    public showMarkerOnRefresh: { type: string, id: string };
    public showGymAtLevel = 14;
    public showPokestopAtLevel = 16;

    private oldBounds: IMapBounds = {
        NE: null,
        SW: null
    }


    constructor(public instanceName: string, public dataService: DataService) {
        var latArr = document.URL.match(/lat=([0-9]*\.?[0-9]+)/);
        var lonArr = document.URL.match(/lon=([0-9]*\.?[0-9]+)/);
        var zoomArr = document.URL.match(/zoom=([0-9]*\.?[0-9]+)/);
        if (latArr && lonArr && latArr.length > 1 && lonArr.length > 1) {
            var lat = parseInt(latArr[1]);
            this.lon = parseInt(lonArr[1]);
            if (zoomArr && zoomArr.length > 1) {
                this.zoom = parseInt(zoomArr[1]);
            } else {
                this.zoom = this.showGymAtLevel;
            }
        }
        this.lastZoom = this.zoom;

        this.raidDialog = new RaidDialog(`${instanceName}.raidDialog`, "#bossList", this)
        this.researchDialog = new ResearchDialog(`${instanceName}.researchDialog`, "#fieldResearch", this)
        this.teamRocketDialog = new TeamRocketDialog(`${instanceName}.teamRocketDialog`, "#teamRocket", this)
        this.searchDialog = new SearchDialog(`${instanceName}.searchDialog`, "#searchModal", this)


    }
    public initMap() {
        // initialize the map

        try {
            if (location.hash && location.hash !== '') {
                let parts = location.hash.substr(1).split(',');
                if (parts.length > 1) {
                    this.lat = parseFloat(parts[0]);
                    this.lon = parseFloat(parts[1]);
                    if (parts.length == 3) {
                        this.zoom = parseInt(parts[2]);
                    }
                }
            }
            else {
                let cookie = CookieManager.getCookie("location");
                if (cookie && cookie.latitude) {
                    this.lat = cookie.latitude;
                    this.lon = cookie.longitude;
                    this.zoom = cookie.zoom
                }
            }
        }
        catch (e) {
            console.error(e)
        }



        this.map = L.map('map').setView([this.lat, this.lon], this.zoom);
        this.map//.locate({setView: false, watch: false}) /* This will return map so you can do chaining */
            //     .on('locationfound', function(e){
            //         console.log("locationfound",e); 
            //     })
            //    .on('locationerror', function(e){
            //         console.log(e);

            //     })
            .on('movestart', (e) => {


                let bounds = this.map.getBounds();
                this.oldBounds.NE = bounds.getNorthEast();
                this.oldBounds.SW = bounds.getSouthWest();
                // console.log("move start",  this.oldBounds)
            })
            .on('zoomstart', (e) => {

              
                let zoom= this.map.getZoom();
                let currentZoom=e.target._zoom;
              //  console.log("zome start", e,currentZoom,zoom)
                if (currentZoom <= this.showGymAtLevel) {
                    if (this.map.hasLayer(this.layers.gym)) {
                        this.map.removeLayer(this.layers.gym)
                    }


                }
                
                if (currentZoom <= this.showPokestopAtLevel) {
                    if (this.map.hasLayer(this.layers.pokestop)) {
                        this.map.removeLayer(this.layers.pokestop)
                    }
                  
                }
                
            })
            .on('moveend', (e) => {

                //  console.log("move end", this.isInitialised)
                this.refresh().then(() => {

                });
            })
            .on('load', (e) => {
                // console.log('load', e);

            })


        var roads = L.gridLayer.googleMutant({
            type: 'roadmap'	// valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
        }).addTo(this.map);
        L.control.locate({ keepCurrentZoomLevel: true, drawCircle: false }).addTo(this.map);
        return this.map;
    }
    public jumpTo(type: string, name: string, latitude, longitude) {

        // console.log("jumpTo",latitude,longitude);
        let minZoom = this.map.getZoom();
        switch (type.toLowerCase()) {
            case "gym":
                minZoom = this.showGymAtLevel
                break;
            case "pokestop":

                minZoom =  this.showPokestopAtLevel
                break;
        }
        this.showMarkerOnRefresh = { type: type, id: name };
        this.map.setView([latitude, longitude], this.zoom > minZoom ? this.zoom : minZoom);

    }
    public async loadMapData() {
        // console.log("loadMapData")
        let currentZoom = this.map.getZoom();
        let bounds = this.map.getBounds();
        this.oldBounds.NE = bounds.getNorthEast();
        this.oldBounds.SW = bounds.getSouthWest();

        this.showingPokestop = currentZoom >= this.showPokestopAtLevel;
        this.showingGyms = currentZoom >=this.showGymAtLevel ;
        await this.raidDialog.init()
        await this.researchDialog.init()
        await this.teamRocketDialog.init()
        await this.searchDialog.init()
        await this.refresh();
        setInterval(async ()=>{
            await this.refresh();
        },15000);
        this.isInitialised = true;
    }
    private async addGymMarkers(gyms: []) {
        //  console.log("add gym markers")
        //  let gyms: [] = await this.dataService.getGyms(mapBounds.NE.lng, mapBounds.NE.lat, mapBounds.SW.lng, mapBounds.SW.lat, this.oldBounds.NE.lng, this.oldBounds.NE.lat, this.oldBounds.SW.lng, this.oldBounds.SW.lat)
        let gymMarker: GymMarker = new GymMarker();

        gyms.forEach((gym: any) => {
            gymMarker.addMarker(gym, this.layers.gym, `${this.instanceName}`);
        });



    }
    private async addPokestopMarkers(pokestops: []) {
        //console.log("add pokestop markers")
        // let pokestops: [] = await this.dataService.getPokestops(mapBounds.NE.lng, mapBounds.NE.lat, mapBounds.SW.lng, mapBounds.SW.lat, this.oldBounds.NE.lng, this.oldBounds.NE.lat, this.oldBounds.SW.lng, this.oldBounds.SW.lat)
        let pokestopMarker: PokestopMarker = new PokestopMarker();

        pokestops.forEach((pokestop: any) => {
            pokestopMarker.addMarker(pokestop, this.layers.pokestop, this.instanceName);
        });

    }
    private async AddReseachMarkers(tasks: []) {
        //console.log("add gym markers")
        //let tasks: [] = await this.dataService.getReseachTasks(neLong, neLat, swLong, swLat)
        let researchMarker: ResearchMarker = new ResearchMarker();
        tasks.forEach((research: any) => {
            researchMarker.addMarker(research, this.layers.research, this.instanceName, false);
        });

    }
    private async AddTRMarkers(tasks: []) {
        //console.log("add gym markers")
        //let tasks: [] = await this.dataService.getTrTasks(neLong, neLat, swLong, swLat)
        let trMarker: TeamRocketMarker = new TeamRocketMarker();
        tasks.forEach((task: any) => {
            trMarker.addMarker(task, this.map, this.instanceName, false);
        });

    }
    private async AddraidMarkers(raids: []) {
        //  console.log("add raid markers")
        // let raids: [] = await this.dataService.getRaids(neLong, neLat, swLong, swLat)
        let raidMarker: RaidMarker = new RaidMarker();
        raids.forEach((raid: any) => {
            raidMarker.addMarker(raid, this.layers.raid, `${this.instanceName}`);
        });

    }
    public copyRaidToClipBoard(gym: string, raidBoss: string, level: number, endTime: string, locUrl: string, maxCp: number, maxCpWb: number) {
        let endDate = new Date(endTime);
        let startDate = new Date(endTime);

        startDate.setMinutes(endDate.getMinutes() - DataService.bossTimeMinutes);
        let text = `${raidBoss} Raid`
        text += `\n\nGym: ${gym}`
        text += `\nTime: ${DataService.formatTime(startDate)} - ${DataService.formatTime(endDate)}`
        if (maxCp) {
            text += `\n100 IV: ${maxCp}/${maxCpWb}`
        }

        text += `\nLocation : ${locUrl}`
        text += `\n\nStart : `
        text += `\n\n1. `
        text += `\n2. `
        text += `\n3. `
        text += `\n4. `
        text += `\n5. `
        let result = ClipBoard.copyToClipBoard(text);
        this.map.closePopup();
    }
    public async refresh() {
        let center = this.map.getCenter()
        let bounds = this.map.getBounds();
        let NE = bounds.getNorthEast();
        let SW = bounds.getSouthWest();
        let mapBounds: IMapBounds = { NE: NE, SW: SW };
        let currentZoom = this.map.getZoom();
        this.zoom = currentZoom;
        const  showGymMarkers =currentZoom >=  this.showGymAtLevel
        const  showPokestopMarkers =currentZoom >=  this.showPokestopAtLevel
        //  console.log("center");
        CookieManager.setCookie("location", { latitude: center.lat, longitude: center.lng, zoom: currentZoom }, 60)
        GoogleAnalitics.registerUrl(`/refresh`)

        let raidMarker = new RaidMarker();
        raidMarker.removeExpiredRaids(this)

        let researchMarker = new ResearchMarker();
        researchMarker.removeExpiredResearch(this)

        let teamRocketMarker = new TeamRocketMarker();
        teamRocketMarker.removeExpiredTasks(this)

        //console.log("refresh", NE, SW, currentZoom)
        let objects = await this.dataService.getObjectsByType(mapBounds.NE.lng, mapBounds.NE.lat, mapBounds.SW.lng, mapBounds.SW.lat, this.oldBounds.NE.lng, this.oldBounds.NE.lat, this.oldBounds.SW.lng, this.oldBounds.SW.lat,showGymMarkers,showPokestopMarkers)
      //  console.log(objects);

        if (showGymMarkers) {

            await this.addGymMarkers(objects.gym);
            if (!this.map.hasLayer(this.layers.gym)) {
                this.map.addLayer(this.layers.gym)
            }

        }
        else {

            if (this.map.hasLayer(this.layers.gym)) {
                this.map.removeLayer(this.layers.gym)
            }

        }
        if (showPokestopMarkers) {

            await this.addPokestopMarkers(objects.pokestop);
            if (!this.map.hasLayer(this.layers.pokestop)) {
                this.map.addLayer(this.layers.pokestop)
            }
        }
        else {
            if (this.map.hasLayer(this.layers.pokestop)) {
                this.map.removeLayer(this.layers.pokestop)
            }
        }
        try {
            await this.AddTRMarkers(objects.teamRocket);
            if (!this.map.hasLayer(this.layers.teamRocketGrunt)) {
                this.map.addLayer(this.layers.teamRocketGrunt)
            }
           
        }
        catch (e) {

        }
        try {
            await this.AddReseachMarkers(objects.research);
            if (!this.map.hasLayer(this.layers.research)) {
                this.map.addLayer(this.layers.research)
            }
            
        }
        catch (e) {

        }
        await this.AddraidMarkers(objects.raid);
        if (!this.map.hasLayer(this.layers.raid)) {
            this.map.addLayer(this.layers.raid)
        }
        

        if (this.showMarkerOnRefresh) {
            let marker: Marker;
            switch (this.showMarkerOnRefresh.type.toLowerCase()) {
                case "gym":
                    marker = new GymMarker();
                    if (!marker.exists(this.showMarkerOnRefresh.id)) {
                        marker = new RaidMarker();
                    }
                    break;
                case "pokestop":
                    marker = new PokestopMarker();
                    if (!marker.exists(this.showMarkerOnRefresh.id)) {
                        marker = new ResearchMarker();
                        if (!marker.exists(this.showMarkerOnRefresh.id)) {
                            marker = new TeamRocketMarker();
                        }
                    }
                    break;
                case "task":
                    marker = new ResearchMarker();
                    break;
            }
            if (marker && marker.exists(this.showMarkerOnRefresh.id)) {
                // console.log('open popup',name);
                marker.get(this.showMarkerOnRefresh.id).openPopup();
                this.showMarkerOnRefresh = null;

            }
        }
        //removeExpiredResearch();
        this.lastZoom = currentZoom;
    }

    // public addFieldResearch(researchName, rewardId) {        
    //     this.dataService.AddResearch(researchName, this.selectedStop.name).then((result) => {
    //         $(this.researchDialog.selector).modal('hide');
    //         var icon = Icon.getIcon(rewardId, 'research');
    //         L.marker([this.selectedStop.latitude, this.selectedStop.longitude], { icon: icon, zIndexOffset: 1 }).bindPopup(`<h6>Field research</h6>${this.selectedStop.name}<br/>${researchName}`).addTo(this.map);       
    //       //  console.log('research added', result)
    //     })


    // }  
    // public addRaid(){
    //   //  console.log("addRaid",this.selectedRaid);
    //     if(this.selectedRaid.pokemonId){
    //         this.selectedRaid.minutes= parseInt($('#raidTimeBoss').children("option:selected").val());
    //     }
    //     else{
    //         this.selectedRaid.minutes= parseInt($('#raidTimeEgg').children("option:selected").val())+45;
    //     }
    //     this.selectedRaid.last_modified=new Date();
    //     this.dataService.addRaid(this.selectedRaid).then(()=>{          
    //         this.raidDialog.hide()

    //         this.refresh().then(()=>{
    //             this.raidDialog.resetAddRaid();
    //         })
    //     })

    // }
}