

declare var window :any;

export class GoogleAnalitics {
    private static measureId:string;
    private static get datalayer(){
        return window.dataLayer
    }
    private static gtag(...args:any){
        this.datalayer.push(arguments)
    }
    public static init() {
        try{
            window.dataLayer = window.dataLayer || [];      
            this.gtag('js', new Date());
            this.gtag('config', GoogleAnalitics.measureId);
        }
        catch(e){
            console.error('GoogleAnalitics init failed',e)
        }
      
    }
    public static registerUrl(url:string):void{
        this.gtag('config', GoogleAnalitics.measureId,{'page_path':url});     
    }

    
    constructor(measureId:string){
        GoogleAnalitics.measureId=measureId;
    }
}