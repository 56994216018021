export class ClipBoard {
    static textArea:HTMLTextAreaElement;
    public static copyToClipBoard(text:string) :any{
        if(!ClipBoard.textArea){
            ClipBoard.textArea = document.createElement('textarea');
            // Move it off screen.
            ClipBoard.textArea.style.cssText = 'position: absolute; left: -99999em';
      
        // Set to readonly to prevent mobile devices opening a keyboard when
        // text is .select()'ed.
            ClipBoard.textArea.setAttribute('readonly', "readonly");
            document.body.appendChild(ClipBoard.textArea);
        }
        ClipBoard.textArea.value = text;
        const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
        
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            const editable = ClipBoard.textArea.contentEditable;
            ClipBoard.textArea.contentEditable = "true";
            const range = document.createRange();
            range.selectNodeContents(ClipBoard.textArea);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            ClipBoard.textArea.setSelectionRange(0, 999999);
            ClipBoard.textArea.contentEditable = editable;
          } else {
            ClipBoard.textArea.select();
          }
          try {
            const result = document.execCommand('copy');
      
            // Restore previous selection.
            if (selected) {
              document.getSelection().removeAllRanges();
              document.getSelection().addRange(selected);
            }
            return result;
           
          } catch (err) {
           console.error(err);
          }
          return false;
    }
}