import { Icon, IconOptions } from "./icon";
import { DataService } from "./dataService";
import { UpdateMode } from "./model";


declare var L: any;


export abstract class Marker {

    static markers: any = {};
    constructor(private type: string) {

        if (!Marker.markers[type]) Marker.markers[type] = {};
    }
    public exists(id: string) {
        return Marker.markers[this.type][id] ? true : false;
    }
    public add(id: string, marker: any) {
        return Marker.markers[this.type][id] = marker;
    }
    public get(id: string) {
        return Marker.markers[this.type][id];
    }
    public remove(id: string) {
        delete Marker.markers[this.type][id];
    }
    protected keys() {
        return Object.keys(Marker.markers[this.type]);
    }
    protected capitalizeFirstLetter(string): string {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    public showPopUp(id: string) {
        let marker = this.get(id);
        if (marker) {
            marker.openPopup();
        }
    }
}
export class GymMarker extends Marker {
    constructor() {
        super("pokestop")
    }
    public addMarker(gym: any, layer: any, dialogInstanceName: string) {

        let raidMarker = new RaidMarker();
        let img = gym.ex_raid ? 'assets/img/exgym.png' : 'assets/img/gym.png'
        var icon = new IconOptions({ iconUrl: img, className: 'gym' });
        if (!this.exists(gym.name)) {
            let gymSafe = gym.name.replace(/"/gi, "&quot;");
            let content = `
            <div class="marker-pop-up">              
                <div><img class="text-icon" src='${img}'/>${gym.name}</div>
                <div><i class="fa fa-road text-icon" aria-hidden="true"></i><a target="_blank" href="https://www.google.com/maps/dir/?api=1&destination=${gym.latitude},${gym.longitude}">Route</a></div>
            </div>
            <br/>
            <button class="btn btn-danger btn-sm btn-block" data-toggle="modal" data-target="#bossList"  data-gym="${gymSafe}" data-updatemode="${UpdateMode.add}" >Add raid</button>`

            let marker = L.marker([gym.latitude, gym.longitude], { icon: icon }).bindPopup(gym.name + '<br/>')
                .bindPopup(content).addTo(layer);
            this.add(gym.name, marker);

        }
    }

}
export class PokestopMarker extends Marker {
    constructor() {
        super("pokestop")
    }
    public addMarker(pokestop: any, layer: any, mapInstanceName: string) {
        // console.log("Add gym marker",gym);        
        var icon = new IconOptions({ iconUrl: 'https://raw.githubusercontent.com/Superviral/Pokemon-GO-App-Assets-and-Images/master/App%20Converted%20Images/img_pokestop.png', className: 'pokestop shrink' });
        if (!this.exists(pokestop.name)) {
            // console.log("added",gym.name);
            let pokestopSafe = pokestop.name.replace(/"/gi, "&quot;");
            let content = `
            <div class="marker-pop-up">              
                <div><img class="img-icon" src='assets/img/pokestop.png'/>${pokestop.name}</div>
                <div><i class="fa fa-road text-icon" aria-hidden="true"></i><a target="_blank" href="https://www.google.com/maps/dir/?api=1&destination=${pokestop.latitude},${pokestop.longitude}">Route</a></div>
            </div>
            <br/>
            <button class="btn btn-danger btn-sm btn-block" data-toggle="modal" data-target="#fieldResearch" data-pokestop="${pokestopSafe}" data-latitude="${pokestop.latitude}" data-longitude="${pokestop.longitude}" data-updatemode="${UpdateMode.add}" >Field research</button>
            <button class="btn btn-danger btn-sm btn-block" data-toggle="modal" data-target="#teamRocket" data-pokestop="${pokestopSafe}" data-latitude="${pokestop.latitude}" data-longitude="${pokestop.longitude}">Team Rocket</button>           
            `

            let marker = L.marker([pokestop.latitude, pokestop.longitude], { icon: icon })
                .bindPopup(content)
                //onclick="${dialogInstanceName}.open('${pokestop.name.replace("\'", "\\\'").replace("\"", "\\\"")}','${pokestop.latitude}', '${pokestop.longitude}',${UpdateMode.add})"
                .addTo(layer);
            this.add(pokestop.name, marker);
        }
    }
}
export class TeamRocketMarker extends Marker {
    constructor() {
        super("pokestop")
    }
    public addMarker(task: any, map: any, dialogInstanceName: string, replace: boolean = false) {
        console.log("Add gym marker", task);
        var icon = Icon.getIcon(task.rewardId, 'team_rocket');
        if (replace) {
            if (this.exists(task.pokestop)) {
                let marker = this.get(task.pokestop);
                map.removeLayer(marker);
                this.remove(task.pokestop);
            }
        }
        if (!this.exists(task.pokestop)) {
            let pokestopMarker = new PokestopMarker();
            if (pokestopMarker.exists(task.pokestop)) {
                let pMarker = pokestopMarker.get(task.pokestop);
                map.removeLayer(pMarker);
                pokestopMarker.remove(task.pokestop);
            }
            let researchMarker = new ResearchMarker();
            if (researchMarker.exists(task.pokestop)) {
                let pMarker = researchMarker.get(task.pokestop);
                map.removeLayer(pMarker);
                researchMarker.remove(task.pokestop);
            }
            let pokestopSafe = task.pokestop.replace(/"/gi, "&quot;");
            let content = `
            <div class="marker-pop-up">   
                <h6>Team Rocket</h6>           
                <table>
                    <tr>            
                        <td>
                            <div><img class="img-icon" src='assets/img/pokestop.png'/>${task.pokestop}</div>
                            <div><i class="fa fa-clock-o text-icon" aria-hidden="true"></i>Reported at ${DataService.formatTime(new Date(task.added))}</div>
                            `
            if (task.comments) {
                content += `<div><i class="fa fa-info-circle text-icon" aria-hidden="true"></i>${task.comments}</div>`;
            }
            content += `               
                            <div><i class="fa fa-road text-icon" aria-hidden="true"></i><a target="_blank" href="https://www.google.com/maps/dir/?api=1&destination=${task.latitude},${task.longitude}">Route</a></div>
                        </td>
                        <td>
                             <img class="boss-icon" src='${icon.options.iconUrl}'/>
                        </td>
                    </tr>
                </table>
            </div>
            <br/>
            `
            content += `  <button class="btn btn-danger btn-sm btn-block" data-toggle="modal" data-target="#teamRocket" data-pokestop="${pokestopSafe}" data-latitude="${task.latitude}" data-longitude="${task.longitude}" data-updatemode="${UpdateMode.update}"  >Update</button>`
            // console.log("added",gym.name);
            let marker = L.marker([task.latitude, task.longitude], { icon: icon, zIndexOffset: 1 })
                .bindPopup(content)
                //onclick="${dialogInstanceName}.open('${research.pokestop.replace("\'", "\\\'").replace("\"", "\\\"")}','${research.latitude}', '${research.longitude}',${UpdateMode.update})"
                .addTo(map);
            marker.task = task;
            this.add(task.pokestop, marker);

        }
        console.log("research", task);
    }
    public removeExpiredTasks(map: any) {
        let keys = this.keys();
        let trMarker: TeamRocketMarker = new TeamRocketMarker();
        keys.forEach((key) => {
            let marker = this.get(key);
            if (marker && marker.task) {
                console.log("checking key", key, marker.task.expires)
                let endDate = new Date(marker.task.expires)
                if (endDate < new Date()) {
                    map.map.removeLayer(marker);
                    this.remove(key);
                    console.log("removed key", key)
                }

            }
        })
    }
}
export class ResearchMarker extends Marker {
    constructor() {
        super("field_research")
    }
    public addMarker(research: any, layer: any, dialogInstanceName: string, replace: boolean = false) {
        // console.log("Add gym marker",gym);
        var icon = Icon.getIcon(research.rewardId, 'research');
        if (replace) {
            if (this.exists(research.pokestop)) {
                let marker = this.get(research.pokestop);
                layer.removeLayer(marker);
                this.remove(research.pokestop);
            }
        }
        if (!this.exists(research.pokestop)) {
            // let pokestopMarker = new PokestopMarker();
            // if (pokestopMarker.exists(research.pokestop)) {
            //     let pMarker = pokestopMarker.get(research.pokestop);
            //     layer.removeLayer(pMarker);
            //     pokestopMarker.remove(research.pokestop);
            // }
            let pokestopSafe = research.pokestop.replace(/"/gi, "&quot;");
            let content = `
                        <div class="marker-pop-up">
                            <h6>Field research</h6>
                            <div><img class="img-icon" src='assets/img/pokestop.png'/>${research.pokestop}</div>
                            <div><img class="img-icon" src='assets/img/quest.png'/>${research.research}</div>`;
            if (research.comments) {
                content += `<div><i class="fa fa-info-circle text-icon" aria-hidden="true"></i>${research.comments}</div>`;
            }
            content += `<div><i class="fa fa-road text-icon" aria-hidden="true"></i><a target="_blank" href="https://www.google.com/maps/dir/?api=1&destination=${research.latitude},${research.longitude}">Route</a></div>`;
            content += `  </div> <br/>`
            content += `  <button class="btn btn-danger btn-sm btn-block" data-toggle="modal" data-target="#fieldResearch" data-pokestop="${pokestopSafe}" data-latitude="${research.latitude}" data-longitude="${research.longitude}" data-updatemode="${UpdateMode.update}"  >Update</button>
                        <button class="btn btn-danger btn-sm btn-block" data-toggle="modal" data-target="#teamRocket" data-pokestop="${pokestopSafe}" data-latitude="${research.latitude}" data-longitude="${research.longitude}">Team Rocket</button>           
            `
            // console.log("added",gym.name);
            let marker = L.marker([research.latitude, research.longitude], { icon: icon, zIndexOffset: 1 })
                .bindPopup(content)
                //onclick="${dialogInstanceName}.open('${research.pokestop.replace("\'", "\\\'").replace("\"", "\\\"")}','${research.latitude}', '${research.longitude}',${UpdateMode.update})"
                .addTo(layer);
            marker.research = research;
            this.add(research.pokestop, marker);

        }

    }
    public removeExpiredResearch(map: any) {
        let keys = this.keys();
        let pokestopMarker: PokestopMarker = new PokestopMarker();
        keys.forEach((key) => {
            let marker = this.get(key);
            if (marker && marker.research) {
                let endDate = new Date(marker.research.expires)
                if (endDate < new Date()) {
                    map.map.removeLayer(marker);
                    this.remove(key);
                    // let pokestop={name:marker.research.pokestop,latitude:marker.research.latitude,longitude:marker.research.longitude}
                    // pokestopMarker.addMarker(pokestop,map.map,`${map.raidDialog.instanceName}`);
                }

            }
        })
    }
}
export class RaidMarker extends Marker {

    constructor() {
        super("raid")
    }
    private getDialog(r, dialogInstanceName, icon) {
        let img = r.ex_raid ? 'assets/img/exgym.png' : 'assets/img/gym.png'
        let boss = r.pokemon_name ? r.pokemon_name : "Level " + r.level
        let endDate = new Date(r.raid_end);
        let startDate = new Date(r.raid_end);
        startDate.setMinutes(endDate.getMinutes() - DataService.bossTimeMinutes);
        if(r.pokemon_name){
            boss= r.pokemon_name;
        }
        else if(r.level===6){
            boss="Mega";
        }
        else if(r.level){
            boss="Level " + r.level
        }
        let gymSafe = r.name.replace(/"/gi, "&quot;");
        // let gymEscape =r.name.replace(/'/gi, "\\\'");
        let content = `
        <div class="marker-pop-up">   
            <h6>${this.capitalizeFirstLetter(boss)} Raid</h6>           
            <table>
                <tr>            
                    <td>
                        <div><img class="img-icon" src='${img}'/>${r.name}</div>
                        <div><i class="fa fa-clock-o text-icon" aria-hidden="true"></i>${DataService.formatTime(startDate)} - ${DataService.formatTime(endDate)}</div>
                        
        `
        if (r.comments) {
            content += `<div><i class="fa fa-info-circle text-icon" aria-hidden="true"></i>${r.comments}</div>`;
        }
        if (r.maxCp) {
            content += `<div><i class="fa fa-exclamation-circle text-icon" aria-hidden="true"></i>${r.maxCp}/${r.maxCpWb}</div>`;
        }
        if (r.minPlayer) {
            content += `<div><i class="fa fa-user text-icon" aria-hidden="true"></i>${r.minPlayer} ${r.minPlayer == 1 ? 'Player' : 'Players'}</div>`;
        }
        content += `      
                        <div><i class="fa fa-road text-icon" aria-hidden="true"></i><a target="_blank" href="https://www.google.com/maps/dir/?api=1&destination=${r.latitude},${r.longitude}">Route</a></div>                 
                    </td>
                    <td>
                        <img class="boss-icon" src='${icon.options.iconUrl}'/>
                    </td>
                </tr>
            </table>
        </div>
        <br/>
        <button class="btn btn-danger btn-sm btn-block" data-toggle="modal" data-target="#bossList"  data-gym="${gymSafe}" data-updatemode="${UpdateMode.update}" data-boss="${this.capitalizeFirstLetter(boss)}" data-end="${r.raid_end}" data-level="${r.level}"data-pokemonid="${r.pokemon_id}" >Update raid</button>
        <button class="btn btn-danger btn-sm btn-block" onclick="PogoMap.copyRaidToClipBoard('${gymSafe}','${this.capitalizeFirstLetter(boss)}',${r.level},'${r.raid_end}','https://pogoutrecht.nl/#${r.latitude},${r.longitude}',${r.maxCp},${r.maxCpWb})" >Copy details</button>`
        return content;
        // let html = "";
        // html += `<h6>Raid</h6>`;
        // html += `Gym : ${r.name}`;
        // html += `<br/>boss : ${r.pokemon_name ? r.pokemon_name : "Level " + r.level}`;
        // if (r.raid_end) html += `<br/>End time : ${DataService.formatTime(new Date(r.raid_end))}`;
        // html += `<br/><button class="btn btn-danger btn-sm btn-block" data-toggle="modal" data-target="#bossList"  data-gym="${r.name}" data-updatemode="${UpdateMode.update}" >Update raid</button>`
        // //onclick="${dialogInstanceName}.open('${r.name.replace("\'", "\\\'").replace("\"", "\\\"")}',${UpdateMode.update})" 
        // return html;
    }
    public addMarker(raid: any, layer: any, dialogInstanceName: string) {

        let marker = this.get(raid.name);

        var icon = null;
        let numberColorClass = 'default';
        let groupID = 0;
        let playerCount = 0;
        if (raid.groups && raid.groups.length) {
            let group:any;
            for(let i=0;i<raid.groups.length;i++){
                let curGroup=raid.groups[i];
                if (curGroup.startTime){
                    let startDate=new Date(curGroup.startTime);
                    if(startDate>=new Date()){
                        group=curGroup;
                        raid.curGroup=i;
                        break;
                    }
                }
                else{
                    group=curGroup;
                    raid.curGroup=i;
                    break;
                }
            }
         
            if(group){
                if (group.playerCount) playerCount = group.playerCount;
                if (group.startTime) {
                    numberColorClass = 'start-time';
                }
                else if (group.playerCount >= raid.minPlayer) {
                    numberColorClass = 'enough-player';
                }
            }
            
        }
        let diffTime = new Date(raid.raid_end).getTime() - new Date().getTime();       
        if (raid.pokemon_id  && diffTime<=45*60*1000 || raid.pokemon_id===225) {
            icon = Icon.getRaidIcon(raid.pokemon_id, playerCount, numberColorClass, 'raid', raid.iconId);
            raid.showAsEgg=false;
        }
        else if (raid.level) {
            icon = Icon.getRaidIcon(-raid.level, playerCount, numberColorClass, 'raid');
            raid.showAsEgg=true;
        }

        if (icon) {           
            if (!marker) {
                marker = L.marker([raid.latitude, raid.longitude], { icon: icon, zIndexOffset: 1 }).bindPopup(this.getDialog(raid, dialogInstanceName, icon)).addTo(layer);
                marker.raid = raid;
                this.add(raid.name, marker);
            }
            else {
                if (this.isChanged(marker.raid,raid)) {                    
                    layer.removeLayer(marker);
                    this.remove(raid.name)
                    marker = L.marker([raid.latitude, raid.longitude], { icon: icon, zIndexOffset: 1 }).bindPopup(this.getDialog(raid, dialogInstanceName, icon)).addTo(layer);
                    marker.raid = raid;
                    this.add(raid.name, marker);
                }
            }
        }
    }
    private isChanged(newRaid,oldRaid){
        let result = newRaid.level != oldRaid.level || newRaid.pokemon_id != newRaid.pokemon_id || newRaid.raid_end != oldRaid.raid_end || newRaid.comments != oldRaid.comments || newRaid.showAsEgg != oldRaid.showAsEgg || newRaid.curGroup != oldRaid.curGroup
        if(newRaid.groups && oldRaid.groups){
           for(let i=0;i<newRaid.groups.length;i++){
               let newGroup=newRaid.groups[i];
               if(i<oldRaid.groups.length){
                    let oldGroup=oldRaid.groups[i];
                    if(newGroup.startTime!==oldGroup.startTime) return true;
                    if(newGroup.playerCount!==oldGroup.playerCount) return true;
               }
           }
        }    
        
        return result;
    }
    public removeExpiredRaids(map: any) {
        let keys = this.keys();
        let gymMarker: GymMarker = new GymMarker();

        keys.forEach((key) => {
            let marker = this.get(key);
            if (marker && marker.raid) {
                let endDate = new Date(marker.raid.raid_end)
                if (endDate < new Date()) {
                    map.map.removeLayer(marker);
                    this.remove(key);
                    // let raid={name:marker.raid.name,latitude:marker.raid.latitude,longitude:marker.raid.longitude}
                    // gymMarker.addMarker(raid,map.map,`${map.raidDialog.instanceName}`);
                }

            }
        })
    }
}